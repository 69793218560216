import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Registration' }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('../views/Registration.vue')
  },
  {
    path: '/registration-success',
    name: 'RegistrationSuccess',
    component: () => import('../views/RegistrationSuccess.vue')
  },
  {
    path: '/admin',
    name: 'AdminHome',
    meta: { guards: ['auth'] },
    component: () => import('../views/admin/Home.vue')
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    meta: { guards: ['guest'] },
    component: () => import('../views/admin/Login.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const storage = JSON.parse(localStorage.getItem('grespRegistrations'));
  if(to.meta?.guards) {
    if( to.meta?.guards.includes('auth') ) {
      if(!storage?.token) {
        next({ name: "AdminLogin" });
      }
    } else if( to.meta?.guards.includes('guest') ) {
      if(storage?.token) {
        router.app.$root.token = storage.token;
        next({ name: "AdminHome" });
      }
    }
  }
  next();
});

export default router;
