<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  beforeCreate() {
    const storage = JSON.parse(localStorage.getItem('grespRegistrations'));
    if( storage?.hasOwnProperty('token') ) {
      this.$root.token = storage.token;
    }
  },
}
</script>